<template>
    <div class="camera-permission full-screen">
        <img src="../assets/images/illustrations/camera.png"/>
        <div class="camera-permission__content">
            <h1>
                {{ $t('CameraPermission.title') }}
            </h1>
            <p>
                {{ $t('CameraPermission.message') }}
            </p>
        </div>

        <pty-button light block :loading="loading" @click="next">{{ $t('Common.consent') }}</pty-button>
    </div>
</template>
<script>
import PtyButton from '../components/PtyButton.vue';
export default {
    name: 'CameraPermission',
    components: {
        PtyButton
    },
    data: () => ({
        loading: false
    }),

    methods: {
        next() {
            this.loading = true;
            // obtain camera permission
            window.navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    setTimeout(() => {
                        // stop using camera
                        stream.getTracks().forEach((track) => {
                            track.stop();
                        });

                        this.loading = false;
                        // go to action selection
                        this.$router.push({name: 'OnBoarding'});
                    }, 1000);
                })
                .catch((e) => {
                    console.log(e);
                    setTimeout(() => {
                        this.loading = false;
                    
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('CameraPermission.error'),
                            position: 'top-center',
                            border: 'primary',
                        });

                        // go to action selection
                        this.$router.push({name: 'OnBoarding'});
                    }, 1000);
                });
        }
    }
}
</script>


<style scoped>
.camera-permission {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background: var(--Linear, linear-gradient(12deg, #00A9C0 0.95%, rgba(0, 169, 192, 0.63) 101.3%));

}

.camera-permission__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
}

h1 {
  color: #FFF;
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.0425rem;
  margin: 0;
}

p {
    color: #FFF;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%; /* 1.88169rem */
}
</style>

